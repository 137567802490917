import React, { useState } from "react"
import { useIntl } from "react-intl"
import { graphql } from "gatsby"

import BaseStructure from "../components/site/baseStructure"
import Seo from "../components/site/seo"
import EventsGrid from "../components/eventsOverview/eventsGrid"
import VideoList from "../components/elements/videoList"
import PageHeader from "../components/elements/pageHeader"
import SubHeading from "../components/elements/subHeading"

import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import Box from "@material-ui/core/Box"

const TripTemplate = ({ data, location }, props) => {
  const intl = useIntl()
  const { mdx, trip } = data
  const allEvents = data.events.edges
    .map(({ node: event }) => event)
    //add title attribute for events from mdx
    .map(event => {
      const { title, subtitle } = data.eventsMdx.edges.find(
        n => n.node.fields.slug === event.fields.slug
      ).node.frontmatter
      return {
        ...event,
        title: title,
        subtitle: subtitle,
      }
    })

  const [showHighlightsOnly, setShowHighlightsOnly] = useState(false)
  const [showHikesOnly, setShowHikesOnly] = useState(false)
  const [events, setEvents] = useState(allEvents)

  React.useEffect(() => {
    let filteredEvents = allEvents
    if (showHighlightsOnly) {
      filteredEvents = filteredEvents.filter(
        event => event.rating === "H" || event.rating === "T"
      )
    }
    if (showHikesOnly) {
      filteredEvents = filteredEvents.filter(event => event.type === "hike")
    }
    setEvents(filteredEvents)
  }, [showHighlightsOnly, showHikesOnly])

  const siteTitle = data.site.siteMetadata.title

  return (
    <BaseStructure location={location} title={siteTitle}>
      <Seo title={mdx.frontmatter.title} description={mdx.body} />
      <Container className="standardWidth">
        <PageHeader title={mdx.frontmatter.longTitle} />
        <FormControlLabel
          control={
            <Switch
              checked={showHighlightsOnly}
              onChange={() => {
                setShowHighlightsOnly(!showHighlightsOnly)
              }}
              name="showHighlightsOnly"
              color="primary"
            />
          }
          label={intl.formatMessage({ id: "page.trip.filter.showHighlightsOnly" })}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showHikesOnly}
              onChange={() => {
                setShowHikesOnly(!showHikesOnly)
              }}
              name="showHikesOnly"
              color="primary"
            />
          }
          label={intl.formatMessage({ id: "page.trip.filter.showHikesOnly" })}
        />
        <Box height="12px"></Box>
        <EventsGrid events={events} />

        {trip.videos && (
          <React.Fragment>
            <Divider />
            <SubHeading
              title={intl.formatMessage({ id: "page.trip.videos.title" })}
            />
            <Container>
              <VideoList
                videos={trip.videos ? trip.videos : false}
              />
            </Container>
          </React.Fragment>
        )}
      </Container>
    </BaseStructure>
  )
}

export default TripTemplate

export const pageQuery = graphql`
  query TripWithEventsBySlug(
    $slug: String!
    $locale: String!
    $tripId: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    trip: tripsJsonJson(fields: { slug: { eq: $slug } }) {
      tripId
      start
      end
      countries
      images {
        imgurAlbumId
      }
      featuredImage {
        imgurId
      }
      videos {
        type
        id
      }
    }
    mdx: mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      body
      frontmatter {
        title
        longTitle
      }
    }
    events: allEventsJsonJson(filter: { tripId: { eq: $tripId } }) {
      edges {
        node {
          fields {
            slug
          }
          type
          rating
          date
          attributes {
            admission
            withDog
          }
          featuredImage {
            imgurId
            alignment
          }
          featuredVideo {
            youtubeId
          }
        }
      }
    }
    eventsMdx: allMdx(filter: { fields: { locale: { eq: $locale } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
  }
`
