import React from "react"
import { useLocalization } from "gatsby-theme-i18n"

import { imgurUrlFromId } from "../../utils/imgurService"
//import { youtubeUrlFromId } from "../../utils/youtubeService"
import { formatDateStd, localizedLink } from "../../utils/localizationService"
import HighlightChips from "../elements/highlightChips"
import EventAttributeIcon from "../elements/eventAttributeIcon"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
//import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
//import IconButton from "@material-ui/core/IconButton"
//import YouTubeIcon from "@material-ui/icons/YouTube"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "500px",
    height: "100%",
  },
  cardArea: {
    height: "100%",
  },
  cardContent: {
    padding: "8px",
  },
  featuredImage: {
    objectPosition: "center center",
  },
  imgTop: {
    objectPosition: "top center !important",
  },
  imgBottom: {
    objectPosition: "bottom center !important",
  },
  date: {
    fontWeight: "100",
    fontSize: "0.9em",
    color: theme.palette.secondary.light,
  },
  subtitle: {
    color: theme.palette.secondary.main,
  },
}))

const EventCard = props => {
  const classes = useStyles()
  const { locale, config } = useLocalization()
  const { event } = props
  const { title, date, rating, featuredImage, attributes } = event
  const featuredImageAlignment = featuredImage.alignment
    ? featuredImage.alignment
    : false

  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.cardArea}
        href={localizedLink("/event" + event.fields.slug, locale)}
      >
        <CardMedia
          className={
            classes.featuredImage +
            (featuredImageAlignment === "top" ? " " + classes.imgTop : "") +
            (featuredImageAlignment === "bottom" ? " " + classes.imgBottom : "")
          }
          component="img"
          alt={title}
          height="200"
          image={imgurUrlFromId(featuredImage.imgurId, "l")}
          title={title}
        />
        <CardContent className={classes.cardContent}>
          <Container className="noHorizontalSpacing verticalFlexBetweenLeft">
            <Typography variant="h6">{title}</Typography>
            {/*<Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>*/}
            <div className="flexSpacer marginBottomS"></div>
            <Container className="noHorizontalSpacing flexBetween marginBottomS">
              <Typography variant="body2" className={classes.date}>
                {formatDateStd(date, locale, config)}
              </Typography>
              <div className="flexSpacer"></div>
              <Container className="noHorizontalSpacing flexEnd spaceM unsetWidth">
                {attributes &&
                  attributes.withDog &&
                  Object.keys(attributes).map(attributeKey => (
                    <EventAttributeIcon attributeKey={attributeKey} key={attributeKey} />
                  ))}
                <HighlightChips rating={rating} size="small" />
              </Container>
            </Container>
          </Container>
        </CardContent>
      </CardActionArea>
      {/*bei mehreren Links wieder einbauen:
         <CardActions disableSpacing>
        {featuredVideo && featuredVideo.youtubeId !== "#" && (
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href={youtubeUrlFromId(featuredVideo.youtubeId)}
            aria-label="watch youtube video"
          >
            <YouTubeIcon />
          </IconButton>
        )}
      </CardActions> */}
    </Card>
  )
}

export default EventCard
