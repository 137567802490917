import React from "react"

import EventCard from "../eventsOverview/eventCard"

//import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

//const useStyles = makeStyles({})

const EventsGrid = props => {
  //const classes = useStyles()
  const events = props.events.sort((a, b) => (a.date < b.date ? 1 : -1))

  //TODO event icon
  return (
    <Grid container spacing={1}>
      {events.map(event => (
        <Grid item xs={12} sm={6} md={4} key={event.fields.slug}>
          <EventCard event={event} />
        </Grid>
      ))}
    </Grid>
  )
}

export default EventsGrid
